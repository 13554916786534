import React, {useState} from "react"
import Image from "../image"

import Arrow from "../../assets/svg/ubuntu-arrow-form.svg"


const YogaTypes = ({onDemand, live, one}) => {
  const
    [status, setStatus] = useState(null),
    [email, setEmail] = useState(''),
    FORM_URL = `https://app.convertkit.com/forms/1639235/subscriptions`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    try {
      const response = await fetch(
        FORM_URL,
        {
          method: 'post',
          body: data,
          headers: {
            accept: 'application/json',
          },
        }
      );
      setEmail('');
      const json = await response.json();
      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const handleInputChange = event => {
    const {value} = event.target;
    setEmail(value);
  }

  return (
    <React.Fragment>
      <div className="yoga--types grid-container-13">
        <Image fluid={live.asset.fluid} alt="Experience Ubuntu Yoga Live"/>
        <div className="live-container">
          <h3>UBUNTU Yoga Live en Español</h3>
          <p>GRATIS: Jueves 18:00 - 19:15 (CDMX)</p>
          <p>Esta práctica es gratuita para todos los niveles y es en vivo vía zoom.</p>
          <form action={FORM_URL} method="post" onSubmit={handleSubmit}>
            <input
              type="email"
              name="email_address"
              placeholder="Email"
              onChange={handleInputChange}
              value={email}
              required
            />
            <button type="submit" className="clickable">Regístrate ahora <Arrow className="clickable"/></button>
          </form>
          {status === 'SUCCESS' && <p className="subscription success">Check your inbox for next steps!</p>}
          {status === 'ERROR' && <p className="subscription error">Something went wrong! try again.</p>}
        </div>
      </div>

      <div className="yoga--types grid-container-13">
        <Image fluid={onDemand.asset.fluid} alt="Experience Ubuntu Yoga On Demand"/>
        <div className="demand-container">
          <h3>UBUNTU Yoga in English</h3>
          <p>Yoga is for everybody. Founded upon the principles of wellbeing and reciprocity, UBUNTU is committed to making this practice available to everyone.</p>
          <p>Practice with us through our Youtube Channel.</p>
          <p>New offerings added every month!</p>
          <div className="cta-container">
            <a className="clickable" href="https://www.youtube.com/channel/UCWjRfWna9fDhtkF2-fww24w?view_as=subscriber" target="__blank">GO TO OUR YOUTUBE CHANNEL <Arrow className="clickable"/></a>
          </div>
        </div>
      </div>

      <div className="yoga--types grid-container-13">
        <Image fluid={one.asset.fluid} alt="Experience Ubuntu Yoga One on One"/>
        <div className="one-container">
          <h3>ubuntu yoga one · one</h3>
          <p>Practice privately with Janine or Maria and tailor your practice to your specific need.</p>
          <p>For more information, register in the form below and our team will reach out to you soon.</p>
          <p>También disponible en español.</p>
          <form name="yoga-oneonone-ubuntu" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field"/>
            <input type="hidden" name="form-name" value="yoga-oneonone-ubuntu"/>
            <input type="text" placeholder="Name"/>
            <input type="email" placeholder="Email"/>
            <button className="clickable">i wanna know more! <Arrow className="clickable"/></button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default YogaTypes
