import React, {useEffect} from "react"
import {graphql} from "gatsby"
import {window, document} from "browser-monads"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/headerSection"
import FirstSectionYoga from "../components/yoga/firstSection"
import SecondSectionYoga from "../components/yoga/secondSection"
import YogaTypes from "../components/yoga/types"
import Menu from "../components/services/menuServices"

export const query = graphql`
query {
  sanityYoga {
    subtitle
    _rawText
    _rawList
    author
    phrase
    quote
    image {
      asset {
        fluid(maxWidth: 2240) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    secondImage {
      asset {
        fluid(maxWidth: 1280) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    yogaDemand {
      asset {
        fluid(maxWidth: 1280) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    yogaLive {
      asset {
        fluid(maxWidth: 1280) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    yogaOne {
      asset {
        fluid(maxWidth: 1280) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
  }
}
`

const Yoga = ({data}) => {
  const {subtitle, _rawText, quote, phrase, author, image, secondImage, _rawList, yogaDemand, yogaLive, yogaOne} = data.sanityYoga

  useEffect(() => {
    window.onscroll = () => {
      Array.prototype.forEach.call(document.querySelectorAll(".yoga--first-section"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t = Math.round(1e5 * ((-e.getBoundingClientRect().top - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e5;
          e.querySelector(".gatsby-image-wrapper").style.transform = `translate3d(0, ${5 * t}%, 0) scale(1)`
        }
      }))
      Array.prototype.forEach.call(document.querySelectorAll(".yoga--second-section"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t = Math.round(1e5 * ((-e.getBoundingClientRect().top - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e5;
          e.querySelector(".gatsby-image-wrapper").style.transform = `translate3d(0, -${5 * t}%, 0) scale(1)`
        }
      }))
    }
  })

  return(
    <Layout>
      <SEO title="Yoga"/>

      <HeaderSection title="Yoga" description={quote} author={author} maxWidth="537px"/>

      <FirstSectionYoga phrase={phrase} image={image}/>

      <SecondSectionYoga text={_rawText} secondImage={secondImage} list={_rawList}/>

      <HeaderSection title="Ubuntu Yoga" description={subtitle} maxWidth="576px"/>

      <YogaTypes onDemand={yogaDemand} live={yogaLive} one={yogaOne}/>

      <Menu/>

    </Layout>
  )
}

export default Yoga
