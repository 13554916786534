import React from "react"

import Image from "../image"
import UbuntuU from "../../assets/svg/ubuntu-u.svg"
import BlockContent from "../../components/block-content"

const SecondSectionYoga = ({text, secondImage, list}) => (
  <div className="yoga--second-section">
    <Image fluid={secondImage.asset.fluid} alt="Experience Ubuntu Yoga"/>
    <div className="u-container">
      <UbuntuU/>
    </div>
    <div className="text-container">
      <BlockContent className="text" blocks={text}/>
      <BlockContent className="list" blocks={list}/>
    </div>
  </div>
)

export default SecondSectionYoga
